<template xmlns:v-on="">
  <div class="row left-align vert-offset-top-1">
    <div class="col s12 l10 offset-l1">
      <div class="card col s12 vert-offset-top-1 padding-top-half">
        <div class="col s12 card-title padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-font">
          <router-link class="black-text" to="/">
            <i class="material-icons">arrow_back</i>
          </router-link>
          <span>
              Register below or <router-link
                  class="homeboy-gold-text underline"
                  to="/transact"
          >
                        Transact
                    </router-link>
          </span>
        </div>

        <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

          <div class="col s12 no-padding">

            <div class="col s12 no-padding vert-offset-top-half vert-offset-bottom-half">
              <div class="col s12 no-padding grey-border border-lighten-2 border-bottom-1">
                <h6>
                  Do you have a <span>BrandBox Service</span> account?
                </h6>
              </div>

            </div>
          </div>

          <div class="col s12 no-padding vert-offset-top-2">

            <div class="col s6 m4 l3">
              <a class="btn btn-block homeboy-gold-text homeboy-charcoal-black" @click.prevent="gotoPage(2)">
                No
              </a>
            </div>
            <div class="col s6 m4 offset-m4 l3 offset-l6">
              <a class="btn btn-block btn homeboy-gold-text homeboy-charcoal-black" @click.prevent="gotoPage(1)">
                Yes
              </a>
            </div>
          </div>

          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>

</template>

<script>




    //import {mapState} from "vuex"
    // import bus from "../../main.js"


    export default {
        name: "RegisterSupplier",
        components:{

        },
        data(){

            return{

            }
        },
        created(){
            this.$store.dispatch('RESET_REGISTRATION');
            this.$setPageHeader();

        },
        methods:
            {
                gotoPage:function(step)
                {
                    if(step === 1)
                    {
                        this.$router.push('/register/supplier/existing');
                    }
                    else {
                        this.$router.push('/register/supplier/new');

                    }
                }

            },

    };


</script>
